<template>
  <section class="banner-section">
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5">
        <div class="col-md-10 col-lg-8 mx-auto text-center">
          <h2 class="text-white mb-5"></h2>
        </div>
      </div>
    </div>
  </section>

  <div>
    <div v-for="credits in $store.state.credits" :key="credits.contract">
      <strong style="color: white">{{ credits.amount }}</strong>
      {{ credits.contract }}
      <button class="btn btn-primary" @click="deposit(credits.contract, 10)">Deposit 10</button>
      <button class="btn btn-primary" @click="withdraw(credits.contract, 10)">Withdraw 10</button>
    </div>
  </div>

  <div>
    <div v-for="withdraw in $store.state.withdraws" :key="withdraw.hash">
      {{ withdraw.amount }}
      {{ withdraw.contract }}
      <button class="btn btn-primary" @click="executeWithdraw(withdraw)">Execute</button>
    </div>
  </div>
</template>

<script>
import {ethers} from "ethers";

const RatDiceAddress = '0x9D6388f88a73e5974CEdabfa907375a9445e576c'

export default {
  data() {
    return {
      socket: null
    }
  },
  watch: {
    '$store.state.address': async function() {
      await this.$store.dispatch('startSocket');
    },
    '$store.state.socketConnecting': function() {
      this.load();
    },
    '$store.state.withdraws': function() {
      this.triggerWithdraws()
    }
  },
  async mounted() {
    if(this.$store.state.address) {
      if(!this.$store.state.socket) {
        await this.$store.dispatch('startSocket');
      } else {
        await this.load();
      }
    }
  },
  methods: {
    load: async function() {
      if(this.$store.state.socket
          && this.$store.state.socketConnecting === false) {
        await this.$store.state.socket.send(JSON.stringify({
          _event: 'ping',
          _message: 'hi'
        }));

        if (this.$store.state.address) {
          await this.$store.state.socket.send(JSON.stringify({
            _event: 'token.withdraws',
            _token: '0x7B4328c127B85369D9f82ca0503B000D09CF9180',
            _amount: 10
          }))
        }
      } else {
        console.log('Nop', this.$store.state.socket.readyState)
      }
    },
    deposit: async function (token, amount) {
      let tokenContract = this.$store.getters.freshTokenContract
      let approved = await tokenContract.allowance(this.$store.state.address, RatDiceAddress)

      if(approved < amount) {
        let approvalTx = await tokenContract.approve(RatDiceAddress, ethers.constants.MaxUint256)
        await approvalTx.wait()
      }

      let ratDiceContract = this.$store.getters.freshDiceContract
      let depositTx = await ratDiceContract.deposit(token, amount)
      let result = await depositTx.wait()

      console.log(result)
      await this.$store.state.socket.send(JSON.stringify({
        _event: 'token.process',
        _tx: result.transactionHash
      }))

    },
    triggerWithdraws: async function() {
      try {
        if(this.$store.state.withdraws.length === 0) {
          return
        }

        let withdraw = this.$store.state.withdraws[0]
        let ratDiceContract = this.$store.getters.freshDiceContract
        let executed = false
        //let executed = ratDiceContract.withdraws(withdraw._withdrawId)
        if(!executed) {
          let withdrawTx = await ratDiceContract.withdraw(
              withdraw.hash,
              this.$store.state.address,
              withdraw.contract,
              withdraw.amount,
              withdraw.signature
          )
          await withdrawTx.wait()
        }
        await this.$store.state.socket.send(JSON.stringify({
          _event: 'token.withdraw.delete',
          _withdrawId: withdraw.hash,
        }))
      } catch(e) {
        console.log(e)
      }
    },
    withdraw: async function (token, amount) {
      await this.$store.state.socket.send(JSON.stringify({
        _event: 'token.withdraw.generate',
        _token: token,
        _amount: amount
      }))
    },
    executeWithdraw: (withdraw) => {
      console.log('Executing withdraw', withdraw)
    }
  }
}
</script>